<template>
    <div class="modal-choose-manager">
        <div class="head flex-row flex-between items-start">
            <div class="title">매니저 선택</div>
        </div>
        <div class="manager-wrap m-t-16">
            <div
                :key="manager"
                v-for="manager in managers"
                class="manager-list"
                :class="manager === selectedManager ? 'manager-list selected' : 'manager-list'"
                @click="selectManager(manager)"
            >
                {{ manager.id }} / {{ manager.name }}
            </div>
        </div>
        <div class="buttons m-t-16">
            <div class="flex-row btns">
                <button @click="onClickClose" class="btn btn-primary cancel m-r-8">취소하기</button>
                <button @click="onClickConfirm" class="btn btn-primary">배정하기</button>
            </div>
        </div>
    </div>
</template>
<script>
import agentService from '@/services/agent'

export default {
    name: 'ModalChooseManager',
    props: ['options'],
    data: () => ({
        managers: null,
        selectedManager: null,
    }),
    mounted() {
        this.getManagerList()
    },
    methods: {
        onClickClose() {
            this.$emit('close', '취소')
        },
        async onClickConfirm() {
            const idx = await this.$modal.basic({
                body: `${this.options.name}님에게 ${this.selectedManager.name}님을 배정하시겠습니까?`,
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                this.$emit('close', this.selectedManager.id)
            }
        },
        selectManager(manager) {
            this.selectedManager = manager
        },
        async getManagerList() {
            try {
                const res = await agentService.get()
                this.managers = res.agents
                this.selectedManager = this.managers[0]
            } catch (err) {
                console.error(err)
            }
        },
    },
}
</script>
<style scoped lang="scss">
.modal-choose-manager {
    .manager-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .manager-list {
            width: 300px;
            border: 2px solid black;
            text-align: center;
            font-size: 16px;
            border-radius: 8px;
            margin-bottom: 10px;
            height: 50px;
            line-height: 46px;
            cursor: pointer;
            color: black;
            font-weight: 600;
        }
        .manager-list.selected {
            background-color: #ddd;
        }
    }
    .buttons {
        .btns {
            .cancel {
                color: black;
                background-color: #fdcb6e;
                &:hover {
                    background-color: #ffeaa7;
                }
            }
        }
    }
}
</style>
